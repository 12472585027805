import React from 'react'
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { auth } from '../firebaseConfig'
import { onAuthStateChanged, /* signOut */ } from 'firebase/auth'

export default function Dashboard() {

    const [authenticatedUser, setAuthenticatedUser] = useState("")
    const navigate = useNavigate()
    const goToLogin = () => {
        navigate("/login")
    }

    // AUTH
    useEffect(() => {
        const listenAuth = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthenticatedUser(user)
            } else {
                setAuthenticatedUser(null)
            }
        })
        return () => {
            listenAuth()
        }
    }, [])

    return (
        <div>
            {
                authenticatedUser === null ?
                    goToLogin() :
                    <div className='dashboard'>
                        <Alert
                            variant="success"
                            className='clients-user'
                        >
                            Você está logado como <strong>{authenticatedUser.email}</strong>
                        </Alert>
                        <h3>Dashboard</h3>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar todos os <strong>PROJETOS</strong> (orçamentos de conexão), {' '}
                            <Alert.Link
                                href="https://drive.google.com/drive/folders/1UHISHv4Wr5lZYRqfpqW-1VU8964PyAu5?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar os <strong>RELATÓRIOS</strong> mais recentes da COELBA, {' '}
                            <Alert.Link
                                href="https://drive.google.com/drive/folders/12xbOKMwNG1SFaBPb4fr5C5shzY0vwh9g?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar as <strong>NORMAS da COELBA</strong> e a <strong>TABELA 36 da NBR 5410</strong>, {' '}
                            <Alert.Link
                                href="https://drive.google.com/drive/folders/15IwfkOskO6A4UhhBsAEEJdabDzvggG4D?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar <strong>DATASHEETS</strong> dos <strong>INVERSORES</strong> mais usados, {' '}
                            <Alert.Link
                                href="https://drive.google.com/drive/folders/1rKfYy6x_xOINn0WytJCeu1R9hys8i0YD?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar <strong>DATASHEETS</strong> dos <strong>MÓDULOS</strong> mais usados, {' '}
                            <Alert.Link
                                href="https://drive.google.com/drive/folders/1prUMTM6H3MQg4SanclxfhePtHHHG2rUb?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar <strong>MÍDIAS</strong>, {' '}
                            <Alert.Link
                                href="https://drive.google.com/drive/folders/1wQiBTVL9rAdSbT22L8wOdSCcNTStLF7V?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar os <strong>CONTRATOS</strong>, {' '}
                            <Alert.Link
                                href="https://drive.google.com/drive/folders/1iQOst3XzWW6fSTsa8YG8I1H9DiCoUaLO?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar os <strong>LOGINS E SENHAS</strong> de todos os clientes, {' '}
                            <Alert.Link
                                href="https://docs.google.com/spreadsheets/d/1iEIHtvV9XAE3pB25GEuCfS-GJtQdzyvmJ-chpYTgGLU/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar o <strong>MODELO DE ORÇAMENTO DE ELÉTRICA</strong>, {' '}
                            <Alert.Link
                                href="https://docs.google.com/document/d/1KMhpRvaFe8sqm6f6SGrzYg3qHyMXpJ55B-CcGjzz87U/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                        <Alert
                            className='dashboard-alert'
                            variant="success"
                        >
                            Para acessar o <strong>MODELO DE ORÇAMENTO SOLAR</strong>, {' '}
                            <Alert.Link
                                href="https://docs.google.com/document/d/1KcguUHfVnfwBHVPCORb0ZipuJ7PMQ-HwEJcCCf3olkE/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui
                            </Alert.Link>!
                        </Alert>
                    </div>
            }
        </div>
    )
}
